import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import { useGetTrailerQuery } from "../features/api";
import VideoPlayer from "./VideoPlayer";
import ErrorBoundary from "../utils/ErrorBoundary";

function HomeTrailer() {
  const {data,error} = useGetTrailerQuery();
  const [videoData,setVideoData] = useState([]);


  useEffect(()=>{
     setVideoData(data?.trailers);
    
  },[data])

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(bg/trailercollage.png)`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        minHeight: "600px",
        
      }}
    >
      <Container className="px-lg-5 ">
        <Row>
          <Col className={` text-white `}
          style={{wordSpacing:'0.2rem'}}
          >
            <h1 className="display-2" style={{letterSpacing:'0.2rem'}}>INFORMATION</h1>
            <h3>THAT IS NOT AVAILABLE ANYWHERE</h3>
          </Col>
        </Row>
        <Row className="">
          <Col md={6}
          
          >
            <Image loading="lazy" src="images/devices.png" fluid />
          </Col>
          <Col className="d-flex align-items-end"         

          >
          
            {
              videoData?.length > 0 && (
              
                  <VideoPlayer
                    poster={videoData[0]?.thumbnail_url}
                    source={videoData[0]?.video_url}
                  />
               
              )
            }

          </Col>
       
        </Row>
      </Container>
    </section>
  );
}



export default HomeTrailer;
