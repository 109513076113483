import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import ProtectedRoute from './utils/ProtectedRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import OtpPage from './pages/OtpPage';
import AccountCreated from './pages/AccountCreated';
import DashboardHome from './pages/DashboardHome';
import VideoCollection from './pages/VideoCollection';
import {  useSelector } from 'react-redux';
import BillingPage from './pages/BillingPage';
import ContentPage from './pages/dashboard/ContentPage';
import Account from './pages/dashboard/Account';
import Watchlist from './pages/dashboard/Watchlist';
import PrivacyPolicy from './pages/contentpages/PrivacyPolicy';
import RefundPolicy from './pages/contentpages/RefundPolicy';
import TermsCondition from './pages/contentpages/TermsCondition';
import Legal from './pages/contentpages/Legal';
import AboutUs from './pages/contentpages/AboutUs';
import ContactUs from './pages/contentpages/ContactUs';
import { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { selectAuth } from './features/authSlice';
import ForgotPassword from './pages/ForgotPassword';
import Aos from 'aos';
import Careers from './pages/contentpages/Careers';
import FreeContent from './pages/FreeContent';
import VideoPage from './pages/VideoPage';
import PricingPolicy from './pages/contentpages/PricingPolicy';
import FAQPage from './pages/contentpages/FAQPage';
import 'react-phone-input-2/lib/style.css'
import "react-loading-skeleton/dist/skeleton.css";

import { SkeletonTheme } from 'react-loading-skeleton';
import DonatePage from './pages/contentpages/Donatepage';
import ShortsPlayer from './components/ShortsPlayer';
import useDetectDevTools from './utils/useDetectDevTools';
import usePreventScrollOnSpace from './utils/usePreventScrollOnSpace';
import AllShorts from './pages/AllShorts';
import ConnectionDetection from './components/ConnectionDetection';
import MobilePaymentRedirection from './pages/MobilePaymentRedirection';

// function App() {

 //const {token} = useSelector((state)=>state.user);

  


  function App () {

  useEffect(() => {
    Aos.init();
  }, []);

// usePreventScrollOnSpace();

    const {refreshToken} = useSelector(selectAuth); 
    const {loading} = useSelector(state=>state.loading);
    const location = useLocation();
    
    
  const protectedRoutes = [
    
    { path: '/dashboard/watchlist', element: <Watchlist /> },
    // { path: '/dashboard/popular-content', element: <ContentPage /> },
    { path: '/dashboard/account', element: <Account /> },
   
     { path: '/auth/billing', element: <BillingPage  />}, 

    // { path: '/genre/:id', element: <VideoCollection /> },
    { path: '/video/:id', element: <VideoPage />}, 

        
  ];


  const isAccountPage = location.pathname.includes('account');
  const isAuthPage = location.pathname.includes('auth');
  const isShortsPage = location.pathname.match('/shorts');
  
  // const isDevToolsOpen = useDetectDevTools();
  const isDevToolsOpen = false;
 
  

  return (
    <>
{isDevToolsOpen ? (
        <div className='text-white' style={{ backdropFilter: 'blur(10px)', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Sensitive information is obscured.</h1>
      </div>
      ) : (
<>
{loading &&(
      <div className='spinner-parent'>
      <div className=" spinner-grow" role="status">
  </div>
  </div>
    )}
      <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 5000 }} />
      <SkeletonTheme baseColor="rgba(0,0,0,0.1)" highlightColor="rgba(250,250,250,0.1)"  enableAnimation={true} duration={1}>

     {/* {isAuthPage ?null :<Header />}  */}
    {!isShortsPage &&  <Header /> }
      <Routes>
        
        {protectedRoutes.map((route, index) => (
        <Route path={route.path} key={index}  element={<ProtectedRoute>{route.element}</ProtectedRoute>} />
        ))}
        
        
        <Route path='/' element={refreshToken?<ProtectedRoute><DashboardHome /></ProtectedRoute>:<Home/>}/>
        {/* <Route path='/home' element={<Home />}/> */}
        <Route path='/auth/signin' element={<Signin />}/>
        <Route path='/auth/signup' element={<Signup />}/>
        <Route path='/auth/otp' element={<OtpPage />}/>
        <Route path='/auth/payment-redirection' element={<MobilePaymentRedirection />}/>
      
        <Route path='/auth/created' element={<AccountCreated />}/>
        <Route path='/auth/forgot-password-mail' element={<ForgotPassword />}/>
        <Route path='/auth/forgot-password-otp' element={<ForgotPassword />}/>
        <Route path='/auth/forgot-password-reset' element={<ForgotPassword />}/>
        

        <Route path='/free-trail' element={<FreeContent />}/>  
        <Route path='/free-trail/all-shorts' element={<AllShorts />}/>  
        <Route path='/free-video/:id' element={<VideoPage />}/>  

        <Route path='/explore' element={<VideoCollection />}/>  
        <Route path='/search' element={<VideoCollection />}/>  
        <Route path='/genre/:id' element={<VideoCollection />}/>  

        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>   
        <Route path='/pricing-policy' element={<PricingPolicy />}/>   
        <Route path='/refund-policy' element={<RefundPolicy />}/>   
        <Route path='/terms-condition' element={<TermsCondition />}/>   
        <Route path='/legal' element={<Legal />}/>   
        <Route path='/faq' element={<FAQPage />}/>   
        <Route path='/about' element={<AboutUs />}/>   
        <Route path='/contact-us' element={<ContactUs />}/>   
        {/* <Route path='/careers' element={<Careers />}/>  */}
        <Route path='/donate' element={<DonatePage />}/> 

        {/* <Route path='/video/:id' element={<VideoPage />}/>    */}

        
        <Route path='/shorts/:id' element={<ShortsPlayer />}/>

        <Route path='*' element={<NotFound />}/>
       
      </Routes>

      {isAccountPage || isAuthPage || isShortsPage ?null:
       <Footer/>
      }
     </SkeletonTheme>

     </>
      )}
            <ConnectionDetection/>

    </>
  );

}
export default App;
