import React, { useEffect, useState } from 'react'
import { Button, Carousel, Col,  Image, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './HomeCarousel.css'
import { imgAddr, useGetOuterCarouselQuery } from '../features/api';
import { clickSubBtn } from '../utils/functions';
import VideoPlayer from './VideoPlayer';
function HomeCarousel() {

    const navigate = useNavigate();
    const {data} = useGetOuterCarouselQuery();
    const [poster,setPoster] = useState([]);
   
    
    useEffect(()=>{
        setPoster(data?.carousels)
    },[data])

  return (
    <section className='section-height'>
    
       
       
    <Carousel  interval={null} controls={true} indicators={true} pause={'hover'}   className=''>
   
    {poster?.map((data, index) => (
      <Carousel.Item className='section-height' key={index} style={{
         backgroundImage: `url(${imgAddr}/${data?.poster_url})`,
          backgroundSize: '100%', backgroundRepeat: 'no-repeat',
      aspectRatio:'16/9'
          
           }}>
 
     {data?.video_id?.video_url &&
     <VideoPlayer source={data?.video_id?.video_url} poster={data?.poster_url}/>

     }
       
       
        {/* <Carousel.Caption style={{height:'50%'}}>
        
        </Carousel.Caption> */}
      </Carousel.Item>
    ))}
  </Carousel>

  <div className='main-logo text-end my-3 px-1'>
            <div
         
             >
              <Image src='logo/StringGeoLogoBrand.png' fluid style={{ maxHeight: '200px' }} />
              </div>
              
               <div
                // style={{position:'absolute',bottom:'10%',right:'0'}}
           
                >
                  <Button
                  onClick={()=>{
                      // navigate('auth/signup')
                      clickSubBtn();
                  }} 
                  size='md'
                  variant='transparent'
                  className='sub-btn my-3 fs-3 px-md-5 nexa-light  fw-bold' 
                  style={{maxWidth:'500px'}}>
                    Subscribe to watch
                  </Button>
                  </div>
          </div>        
  
  </section>
  )
}

export default HomeCarousel