import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  FaArrowLeft,
  FaBackward,
  FaCompress,
  FaExpand,
  FaForward,
  FaGear,
  FaPause,
  FaPlay,
  FaVolumeHigh,
  FaVolumeLow,
  FaVolumeXmark,
} from "react-icons/fa6";
import { Button, Image, Spinner } from "react-bootstrap";
import "./VideoPlayer.css";
import "rc-slider/assets/index.css";
import screenfull from "screenfull";
import { FaAngleRight, FaCog } from "react-icons/fa";
import { MdReplay } from "react-icons/md";
import ErrorBoundary from "../utils/ErrorBoundary";
import { imgAddr, vidAddr } from "../features/api";
import { useLocation } from "react-router-dom";

const VideoPlayer = ({ source, poster  }) => {
  const [firstPlay,setFirstPlay] = useState(true);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState("Auto");
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [hoveredTime, setHoveredTime] = useState(null);
  const [preloaded, setPreloaded] = useState(false); 
  const [showControls, setShowControls] = useState(true);
  const [showRestartButton, setShowRestartButton] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [src, setSrc] = useState(`${vidAddr}/${source}/360p.m3u8`);
  const playerRef = useRef(null);
  const progressRef = useRef(null);
  const containerRef = useRef(null);
  const menuRef = useRef(null);
  let timeoutId;



  const playbackOptions = [0.5, 0.75, 1, 1.25, 1.5, 2];

  // src='https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8';
  // src='https://d2n9feutzfz8ux.cloudfront.net/mu/720p.m3u8';

  const imgSrc = `${imgAddr}/${poster}`;

  const location = useLocation();
  const isVideoPage = location.pathname.includes("/video");
  const isFreeVideoPage = location.pathname.includes("/free-video");

  const [isInView, setIsInView] = useState(false);
  useEffect(() => {

    if(!(isVideoPage || isFreeVideoPage)){

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setPlaying(true)
          } else {
            setPlaying(false)
          }
        });
      },
      { threshold: 0.5 } 
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }

  }, []);

  useEffect(() => {
    const onTouchStart = () => {
      setIsTouchDevice(true);
    };

    if ("ontouchstart" in window || navigator.maxTouchPoints) {
      setIsTouchDevice(true);
    }

    window.addEventListener("touchstart", onTouchStart);

    return () => {
      window.removeEventListener("touchstart", onTouchStart);
    };
  }, []);

  useEffect(() => {
    if (isVideoPage || isFreeVideoPage) {
      const handleKeyDown = (e) => {
        switch (e.key) {
          case " ":
            e.preventDefault();
            handlePlayPause();
            break;
          case "ArrowLeft":
            e.preventDefault();
            handleBackward();
            break;
          case "ArrowRight":
            e.preventDefault();
            handleForward();
            break;
          default:
            break;
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [playing]);

  const handleReady = () => {
    setLoading(false);
    // setPlaying(true);
  };

  const handleBuffer = () => {
    setLoading(true);
  };

  const handleBufferEnd = () => {
    setLoading(false);
  };

  const handlePlayPause = () => {
    
    setPlaying((prevPlaying) => !prevPlaying);
    setShowRestartButton(false);
  };

  useEffect(() => {
    if (!playing) {
      setShowControls(true);
    }
  }, [playing]);

  const handleEnded = () => {
    setPlaying(false);
    setShowRestartButton(true);
  };

  const handleRestart = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      setPlaying(true);
      setShowRestartButton(false);
    }
  };

  const handleBackward = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(
        playerRef.current.getCurrentTime() - 10,
        "seconds"
      );
    }
  };

  const handleForward = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(
        playerRef.current.getCurrentTime() + 10,
        "seconds"
      );
    }
  };

  const handleSeekChange = (e) => {
    const value = parseFloat(e.target.value);
    setPlayed(value);
    setShowRestartButton(false);
    if (playerRef.current) {
      playerRef.current.seekTo(value / 100, "fraction");
    }
  };

  const handleSeekMouseDown = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(progressRef.current.value / 100, "fraction");
    }
  };

  const handleProgress = (state) => {
    if (!progressRef.current) return;
    const { played, playedSeconds, loaded } = state;

    const progressPercentage = played * 100;
    const loadedPercentage = loaded * 100;

    setPlayed(progressPercentage);

    setCurrentTime(playedSeconds);

    const progressBar = progressRef.current;
    if (progressBar) {
      const progressColor = `linear-gradient(to right, #CAA257 ${
        progressPercentage + 0.1
      }%, rgba(255,255,255,0.6) ${progressPercentage}%, rgba(255,255,255,0.6) ${loadedPercentage}%, rgba(255,255,255,0.2) ${loadedPercentage}%)`;
      progressBar.style.background = progressColor;
    }
  };

  const handleProgressHover = (e) => {
    const barWidth = progressRef.current.getBoundingClientRect().width;
    const mouseX = e.clientX - progressRef.current.getBoundingClientRect().left;
    const hoverTime = (mouseX / barWidth) * duration;
    setHoveredTime(hoverTime);

    const tooltip = document.querySelector(".tooltip-progress");
    if (tooltip) {
      const tooltipWidth = tooltip.getBoundingClientRect().width;
      const tooltipWidthPercentage = (tooltipWidth / barWidth) * 100;

      let tooltipPositionX = (mouseX / barWidth) * 100;
      if (tooltipPositionX < tooltipWidthPercentage) {
        tooltipPositionX = tooltipWidthPercentage;
      } else if (tooltipPositionX + tooltipWidthPercentage > 100) {
        tooltipPositionX = 100 - tooltipWidthPercentage;
      }
      tooltip.style.left = `calc(${tooltipPositionX}% )`;
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleVolumeChange = (e) => {
    const value = parseFloat(e.target.value);
    setVolume(value);

    const volumeTrack = document.querySelector(".volume-track");
    if (volumeTrack) {
      const coloredWidth = value * 100 + "%";
      volumeTrack.style.background = `linear-gradient(to right, #CAA257 ${coloredWidth}, rgba(255,255,255,0.8) ${coloredWidth})`;
    }
  };

  const handleMute = () => {
    if (volume === 0) {
      setVolume(1);
    } else {
      setVolume(0);
    }
  };

  const volumeIcon = () => {
    if (volume === 0) {
      return <FaVolumeXmark />;
    } else if (volume < 0.5) {
      return <FaVolumeLow />;
    } else {
      return <FaVolumeHigh />;
    }
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        setIsFullScreen(screenfull.isFullscreen);
      });

      return () => {
        screenfull.off("change");
      };
    }
  }, []);

  // const toggleFullScreen = () => {
  //   if (screenfull.isEnabled) {
  //     if (!isFullScreen) {
  //       screenfull.request(containerRef.current);
  //     } else {
  //       screenfull.exit();
  //     }
  //     setIsFullScreen((prevFullScreen) => !prevFullScreen);
  //   }
  // };

  // const toggleFullScreen = () => {
  //   if (!isFullScreen) {
  //     if (containerRef.current) {
  //       containerRef.current.requestFullscreen();
  //     }
  //   } else {
  //     if (document.fullscreenElement) {
  //       document.exitFullscreen();
  //     }
  //   }
  //   setIsFullScreen(prevFullScreen => !prevFullScreen);
  // };

  const fullScreenIcon = () => {
    return screenfull.isFullscreen ? <FaCompress /> : <FaExpand />;
  };

  const toggleSettings = () => {
    setActiveMenu("main");
    setShowSettings(!showSettings);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowSettings(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuChange = (menu) => {
    setActiveMenu(menu);
  };

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    toggleSettings();
  };

  const handleError = (error) => {
    console.error("An error occurred while loading the video:", error);
  };

  const handleQualityChange = (quality) => {
    if (quality === 360) {
      setSelectedQuality("Auto");
    } else {
      setSelectedQuality(quality);
    }

    const newSrc = `${vidAddr}/${source}/${quality}p.m3u8`;
    if (newSrc !== src) {
      const currentTime = playerRef.current.getCurrentTime();
      setPlaying(true);
      setSrc(newSrc);

      setTimeout(() => {
        if (playerRef.current) {
          playerRef.current.seekTo(currentTime);
        }
      }, 1000);
    }
    toggleSettings();
  };

  const resetTimeout = () => {
    if (containerRef?.current?.classList) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (containerRef?.current?.classList?.contains("show-controls")) {
          containerRef?.current?.classList?.remove("show-controls");
          setShowControls(false);
        }
      }, 8000);
    }
  };


  const isIOS = () => {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream
    );
  };
  
  const toggleFullScreen = () => {
    if (isIOS()) {
      const videoElement = playerRef.current.getInternalPlayer();
      if (videoElement.webkitEnterFullscreen) {
        if (!isFullScreen) {
          videoElement.webkitEnterFullscreen();
        } else {
          videoElement.webkitExitFullscreen();
        }
      }
    } else {
      if (screenfull.isEnabled) {
        if (!isFullScreen) {
          screenfull.request(containerRef.current);
        } else {
          screenfull.exit();
        }
        setIsFullScreen((prevFullScreen) => !prevFullScreen);
      }
    }
  };
  
  useEffect(() => {
    if (!isIOS()) {
      const handleFullScreenChange = () => {
        setIsFullScreen(screenfull.isFullscreen);
      };
  
      if (screenfull.isEnabled) {
        screenfull.on("change", handleFullScreenChange);
  
        return () => {
          screenfull.off("change", handleFullScreenChange);
        };
      }
    } else {
      const handleFullScreenChange = () => {
        setIsFullScreen(document.fullscreenElement != null);
      };
  
      document.addEventListener("fullscreenchange", handleFullScreenChange);
  
      return () => {
        document.removeEventListener("fullscreenchange", handleFullScreenChange);
      };
    }
  }, []);
  

  // useEffect(() => {
  //   const handleFullScreenChange = () => {
  //     setIsFullScreen(!!document.fullscreenElement);
  //   };

  //   document.addEventListener("fullscreenchange", handleFullScreenChange);

  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullScreenChange);
  //   };
  // }, []);


  useEffect(()=>{
  console.log('playing: ',playing)
  },[playing])



  const settingsMenu = () => {
    return (
      <div className={`settings-wrapper  ${showSettings ? "show" : ""}`}>
        <div className="settings-menu">
          <div className="menu-header">
            {activeMenu !== "main" && (
              <FaArrowLeft
                className="back-icon"
                onClick={() => handleMenuChange("main")}
              />
            )}
            <span className="menu-title">
              {activeMenu === "main" ? "Settings" : `${activeMenu === 'submenu1'?'Quality':'Playback Rate'}`}
            </span>
          </div>
          <ul className="menu-items">
            {activeMenu === "main" && (
              <>
                <li
                  onClick={() => handleMenuChange("submenu1")}
                  className="my-2 d-flex justify-content-between"
                >
                  <span>Quality:</span>
                  <span>
                    {selectedQuality} <FaAngleRight />
                  </span>
                </li>
                <li
                  onClick={() => handleMenuChange("playbackspeed")}
                  className="d-flex justify-content-between"
                >
                  <span>Playback Rate:</span>
                  <span>
                    {playbackSpeed}x <FaAngleRight />
                  </span>
                </li>
              </>
            )}
            {activeMenu === "submenu1" && (
              <>
               <li
                  onClick={() => handleQualityChange(360)}
                  className={selectedQuality === 'Auto' ? "active" : ""}
                >
                  Auto
                </li>
              <li
                  onClick={() => handleQualityChange(1080)}
                  className={selectedQuality === 1080 ? "active" : ""}
                >
                  1080P
                </li>
                <li
                  onClick={() => handleQualityChange(720)}
                  className={selectedQuality === "720" ? "active" : ""}
                >
                  720P
                </li>
                
               
              </>
            )}
            {activeMenu === "playbackspeed" &&
              playbackOptions?.map((speed, index) => (
                <li
                  key={index}
                  className={playbackSpeed === speed ? "active" : ""}
                  onClick={() => handleSpeedChange(speed)}
                >
                  {speed}x
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  };



  useEffect(() => {

    const preloadVideo = () => {
     playerRef?.current && playerRef?.current?.getInternalPlayer()?.load();
    };

    if (preloaded) {
      setLoading(false)
      setPlaying(true);
    
    }


    preloadVideo();
  }, [preloaded,playerRef]);


  return (
    <div
      className={`video-container ${playing ? "playing" : "paused"}`}
      ref={containerRef}
      onMouseMove={() => {
        containerRef.current.classList.add("show-controls");
        setShowControls(true);
        resetTimeout();
      }}
    >
      {/* {firstPlay && !playing ? (
        <div className="thumbnail-poster">
          <Image
              src={imgSrc}
              style={{ height: "100%", width: "100%" }}
              alt="Thumbnail"
              onClick={()=>
                {setFirstPlay(false)
                  setPlaying(true)
                }
              }
            />
        </div>
      ) : null} */}
      {loading ? (
        <div className="loading-indicator text-white">
          <Spinner size="xl" />
        </div>
      ) : null}

      <div
        className="video-player"
        onClick={() => {
          if (showControls) {
            handlePlayPause();
          }
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={src}
          playing={playing}
          controls={false}
          width="100%"
          height="100%"
          playbackRate={playbackSpeed}
          volume={volume}
          // light={false}
          // playIcon={<FaPlay/>}
          light={
            <Image
              src={imgSrc}
              style={{ height: "100%", width: "100%", zIndex: 99 }}
              alt="Thumbnail"
              onClick={() => {
                setLoading(true)
                setPreloaded(true)
              }
              }
            />
          }
          
          // onReady={()=>setPlaying(true)}
          playsinline={true}
          onClickPreview={()=>setLoading(true)}
          onStart={()=>setPlaying(true)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onEnded={handleEnded}
          onBuffer={handleBuffer}
          onError={handleError}
          onBufferEnd={handleBufferEnd}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          config={{
            hls: {
              forceHLS: true,
            },
            // dash: {
            //   forceDASH: true
            // },
          }}
        />
      </div>
      <div
        className={`player-controls ${
          isFullScreen ? "fullscreen-controls" : ""
        } `}
      >
        {showRestartButton ? (
          <Button onClick={handleRestart}>
            <MdReplay />
          </Button>
        ) : (
          <Button onClick={handlePlayPause}>
            {playing ? <FaPause /> : <FaPlay />}
          </Button>
        )}
        <Button onClick={handleBackward}>
          <FaBackward />
        </Button>
        <Button onClick={handleForward}>
          <FaForward />
        </Button>

        {isTouchDevice ? null : (
          <div className="volume-wrapper">
            <Button onClick={handleMute}>{volumeIcon()}</Button>
            <input
              type="range"
              className="volume-track"
              style={{
                background: `linear-gradient(to right, #CAA257 ${
                  volume * 100
                }%, rgba(255,255,255,0.8) ${volume * 100}%)`,
              }}
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={handleVolumeChange}
            />
          </div>
        )}

        <span className="text-white ms-2">
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>

        <div
          className="progress-bar-wrapper"
          onMouseMove={handleProgressHover}
          onMouseLeave={() => setHoveredTime(null)}
        >
          {
            <div
              className="tooltip-progress"
              //  style={{ left: `${(hoveredTime / duration) * 100}%` }}
            >
              <p>{formatTime(hoveredTime)}</p>
            </div>
          }

          <input
            type="range"
            className="track-range"
            ref={progressRef}
            min={0}
            max={100}
            value={played}
            step="any"
            onChange={handleSeekChange}
            onMouseDown={handleSeekMouseDown}
          />
        </div>

        <div className="quality" ref={menuRef}>
          {settingsMenu()}

          <Button
            className={`settings-button ${showSettings ? "active" : ""}`}
            onClick={toggleSettings}
          >
            <FaCog />
          </Button>
        </div>

        <Button onClick={toggleFullScreen}>{fullScreenIcon()}</Button>
      </div>
    </div>
  );
};

export default VideoPlayer;
